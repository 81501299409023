

$(function () {

    var mmConfig = {
    awaitCloseAnimation: true,
    awaitOpenAnimation: true,
    }

    MicroModal.init(mmConfig);


    jarallax(document.querySelectorAll('.jarallax'), {
        speed: 0.2
    });


    var mySwiper = new Swiper ('.swiper-container', {
    // Optional parameters
    loop: true,

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })


});
