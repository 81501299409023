$(function() {
	$('.hamburger-button').on('click', function(event){
		event.preventDefault();

		$(this).toggleClass('active');
		$('.nav-wrapper').toggleClass('visible');
	});

		var cbpAnimatedHeader = (function() {

		var docElem = document.documentElement,
			header = document.querySelector( '.header-bar' ),
			didScroll = false,
			changeHeaderOn = 150;

		function init() {
			window.addEventListener( 'scroll', function( event ) {
				if( !didScroll ) {
					didScroll = true;
					setTimeout( scrollPage, 100 );
				}
			}, false );
		}

		function scrollPage() {
			var sy = scrollY();
			if ( sy >= changeHeaderOn ) {
				classie.add( header, 'header-bar-shrink' );
			}
			else {
				classie.remove( header, 'header-bar-shrink' );
			}
			didScroll = false;
		}

		function scrollY() {
			return window.pageYOffset || docElem.scrollTop;
		}

		function scrollToSection(event) {
	    event.preventDefault();
	    var $section = $($(this).attr('href'));
	    $('html, body').animate({
			scrollTop: $section.offset().top - $('.header-bar-shrink').height()
		}, 500);
		$('.hamburger-button').removeClass('active');
		$('.nav-wrapper').removeClass('visible');
	  }
	  $('[data-scroll]').on('click', scrollToSection);

		init();

	})();



});


